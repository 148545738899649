*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.firebase-emulator-warning {
  visibility: hidden;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container{
  position: relative;
  min-height: 100vh;
  padding-bottom: 2rem;
}

.navbar-nav{
  width: 100%;
}

.menu{
  position: relative;
}
.submenu{
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  white-space: nowrap;
  display: none;
  z-index: 10;
}
.menu:hover .submenu{
  display: block;
}

.balances{
  width: 100%;
  left: 0;
  position: absolute;
  bottom: 2em;
}

.fila_lote input:focus{
  box-shadow: none;
}

.switch{
  position: absolute;
  left: 0;
  top: -100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.switch-button {
  display: inline-block;
  margin-left: 1em;
}
.switch-button .switch-button__checkbox {
  display: none;
}
.switch-button .switch-button__label {
  cursor: pointer;
  background-color: #fe5e41;
  width: 2.5rem;
  height: 1.5rem;
  border-radius: 1.5rem;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.switch-button .switch-button__label:before {
  transition: .2s;
  display: block;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #fdffff;
  content: '';
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 1px #000;
}
.switch-button .switch-button__checkbox:checked + .switch-button__label {
  background-color: #00a878;
}
.switch-button .switch-button__checkbox:checked + .switch-button__label:before {
  transform: translateX(1rem);
}

.swiper {
  width: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #F5F5F5;
  margin-bottom: 16px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  height: 150px;
}
.swiper-slide div {
  position: relative;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  overflow: hidden;
}
.swiper.disabled .swiper-slide div::after{
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
.swiper.disabled .swiper-slide div::before{
  content: "AGOTADO";
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 4;
  transform: translateY(-50%);
  color: white;
  font-weight: 600;
  text-align: center;
  background: #F75C03;
  width: 100%;
  line-height: 1;
  padding: 2px 0;
  font-size: 8px;
}

.swiper-pagination {
  bottom: 0 !important;
}
.swiper-pagination-bullet {
  background: #D9D9D9 !important;
  opacity: 1 !important;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #616161 !important;
}

/* page catalogo details product */
.swiper.details-page .swiper-slide {
  background: #fff;
  padding: 5px;
}
.swiper.details-page .swiper-slide div{
  border-radius: 25px;
  box-shadow: 0px 3.05093px 7.62732px rgba(0, 0, 0, 0.25);
}
.swiper.details-page .swiper-slide img{
  height: 330px;
}